
// Mobile menu toggle
$(function () {
    $("#menu-toggle").click(function () {
        $(this).toggleClass("on");
        $("#mobile-menu").slideToggle(200);
    });
    $("#mobile-menu .a1").click(function () {
        $("#menu-toggle").removeClass("on");
        $("#mobile-menu").slideToggle(200);
    });
});
// Smooth Scrolling, animate anchor links
$(function() {
  $('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({scrollTop: target.offset().top - 50}, 500);
        return false;
      }
    }
  });
});
